<template>
  <div class="relative min-h-screen">
    <div v-if="mode == 'newInvoice'">
      <div class="mb-3">
        <div class="text-right">
          <button
            @click="
              $router.push({
                name: 'manage_customer',
                query: { redirect: 'manage_invoice' },
              })
            "
            type="button"
            class="text-darkblue mr-2 hover:bg-darkblue border-2 border-darkblue md:text-xs mb-3 mt-3 hover:text-white pl-3 pt-1 pb-1 pr-3 rounded-full"
          >
            Create Customer
          </button>
        </div>
        <div
          class="w-full bg-white p-6 rounded-lg md:w-6/12 mb-3 md:mb-6 ml-auto mr-auto"
        >
          <h1 class="font-bold text-lg text-center text-darkblue">
            Invoice Details
          </h1>

          <ValidationObserver v-slot="{ handleSubmit }">
            <form
              ref="customersForm"
              @submit.prevent="handleSubmit(SubmitCustomer)"
            >
              <div class="mb-4">
                <label class="block md:text-xs" for="Invoice no">
                  Physical Invoice No
                </label>
                <validation-provider
                  rules="required|alpha_num"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    v-model="physicalInvoiceNo"
                    name="Invoice no"
                    class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="mb-4">
                <label class="block md:text-xs" for="stock_type">
                  Customer
                </label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <t-rich-select
                    name="Customer"
                    v-model="oldCustomer.index"
                    placeholder="Select Customer"
                    :fixedClasses="rich_select_component_classes"
                    :classes="rich_select_default_component_classes"
                    :options="customers"
                  ></t-rich-select>

                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div v-if="oldCustomer.index" class="mb-4">
                <p class="md:text-xs mb-3">
                  <span class="text-darkblue md:text-xs font-bold">
                    Customer Name
                  </span>
                  : {{ oldCustomer.name }}
                </p>
                <p class="mb-3 md:text-xs">
                  <span class="text-darkblue md:text-xs font-bold"
                    >Customer Email
                  </span>
                  : {{ oldCustomer.email }}
                </p>
                <p class="mb-3 md:text-xs">
                  <span class="text-darkblue md:text-xs font-bold"
                    >Customer Phone </span
                  >: {{ oldCustomer.phoneNumber }}
                </p>
                <p class="mb-3 md:text-xs">
                  <span class="text-darkblue md:text-xs font-bold"
                    >Customer Address </span
                  >: {{ oldCustomer.address }}
                </p>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div v-if="tableData.length > 0" class="mt-6">
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
          <template slot="column" slot-scope="props">
            <td v-if="props.text === 'action'" :class="props.tdClass">
              <button
                @click="DeleteInvoiceItem(props.rowIndex)"
                class="text-white font-bold md:text-xs md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
              >
                <font-awesome-icon
                  title="Delete"
                  :class="'text-red-600 hover:text-black text-sm'"
                  :icon="['far', 'trash-alt']"
                />
              </button>
            </td>
            <td v-else :class="props.tdClass">{{ props.text }}</td>
          </template>
        </t-table>
        <p class="text-darkblue text-xs mt-2">
          <span class="font-bold"> Invoice Total</span> :₦{{
            invoiceTotal.toLocaleString()
          }}
        </p>
      </div>
      <div class="text-center">
        <button
          @click="mode = 'newInvoiceItem'"
          type="button"
          class="text-darkblue mr-2 hover:bg-darkblue border-2 border-darkblue md:text-xs mb-3 mt-3 hover:text-white pl-3 pt-1 pb-1 pr-3 rounded-full"
        >
          Add Invoice Item
        </button>
        <button
          @click="$refs.customersForm.requestSubmit()"
          type="button"
          class="text-white md:text-xs mb-3 mt-3 bg-green-600 hover:bg-darkblue pl-3 pt-2 pb-2 pr-3 rounded-full"
        >
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          Save Invoice
        </button>
      </div>
    </div>
    <div v-if="mode == 'newInvoiceItem'">
      <div class="   ">
        <div class="w-full ml-auto mr-auto rounded-lg md:w-8/12 mb-3 md:mb-6">
          <div class="bg-white p-3 md:p-6 mb-4 rounded-lg">
            <h1 class="font-bold text-lg text-center text-darkblue">
              Add Invoice Item
            </h1>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(AddStockBreakdown)">
                <div class="mb-4">
                  <label class="block md:text-xs" for="stock_type">
                    Stock Item
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <t-rich-select
                      name="Stock item"
                      :required="true"
                      v-model="selectedStock.index"
                      placeholder="Select Stock Item"
                      :fixedClasses="rich_select_component_classes"
                      :classes="rich_select_default_component_classes"
                      :options="stockOptions"
                    ></t-rich-select>
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="mb-4">
                  <label class="block md:text-xs" for="Selling price">
                    Unit Selling Price(₦)</label
                  >
                  <validation-provider
                    rules="required|numeric|min_value:1"
                    v-slot="{ errors }"
                  >
                    <input
                      @change="
                        selectedStock.itemTotal = 0;
                        selectedStock.quantityBreakdown = [];
                        selectedStock.quantity = 0;
                      "
                      v-model="selectedStock.unitSellingPrice"
                      type="text"
                      name="Selling price"
                      class="border-2 w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="mb-4">
                  <label class="block md:text-xs" for="stock_type">
                    Purchase Batch
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <t-rich-select
                      :required="true"
                      name="Purchase batch"
                      v-model="selectedPurchasedItem.index"
                      placeholder="Select Purchase Batch"
                      :fixedClasses="rich_select_component_classes"
                      :classes="rich_select_default_component_classes"
                      :options="purchasedItemsOptions"
                    ></t-rich-select>
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                  <small class="text-darkblue font-bold block text-xs mt-2"
                    >'R' is quantity remaining <br />

                    'C' is unit cost price
                  </small>
                </div>

                <div class="mb-3">
                  <label class="block md:text-xs" for="sellingQuantity"
                    >Quantity To Sell</label
                  >
                  <validation-provider
                    rules="required|double|min_value:0.5"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="selectedPurchasedItem.quantityRemoved"
                      type="text"
                      name="Quantity to sell"
                      class="border-2 w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="text-center">
                  <button
                    @click="mode = 'newInvoice'"
                    type="button"
                    class="text-white mb-3 mr-2 border border-red-700 bg-red-700 md:text-xs pl-3 pt-2 pb-2 pr-3 rounded-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="text-darkblue mb-3 border border-darkblue md:text-xs pl-3 pt-2 pb-2 pr-3 rounded-full"
                  >
                    Add Batch Item
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div
            v-if="selectedStock.quantityBreakdown.length > 0"
            class="bg-white p-6 rounded-lg"
          >
            <h1 class="font-bold mb-1 text-lg text-center text-darkblue">
              Invoice Item Breakdown
            </h1>

            <table class="table-auto mb-3 w-full text-center">
              <thead>
                <tr class="md:text-xs">
                  <th class="">Batch No</th>
                  <th class="">Qty to Sell</th>
                  <th class="">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="selectedStock.quantityBreakdown.length < 1"
                  class="md:text-xs bg-dashboardbody"
                >
                  <td class="pt-1 pb-1" colspan="3">No Batch added yet</td>
                </tr>
                <tr
                  v-for="(
                    quantityBreakdown, index
                  ) in selectedStock.quantityBreakdown"
                  :key="index"
                  class="md:text-xs bg-dashboardbody"
                >
                  <td class="pt-1 pb-1">
                    {{ quantityBreakdown.serialId }}
                  </td>
                  <td class="pt-1 pb-1">
                    {{ quantityBreakdown.quantityRemoved }}
                  </td>

                  <td class="pt-1 pb-1">
                    <button @click="RemoveQtyBreakdown(index)">
                      <font-awesome-icon
                        title="Delete"
                        :class="'text-red-600 hover:text-black text-sm'"
                        :icon="['far', 'trash-alt']"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              @click="AddInvoiceItem"
              :class="{
                'cursor-not-allowed':
                  selectedStock.quantityBreakdown.length < 1,
              }"
              :disabled="selectedStock.quantityBreakdown.length < 1"
              type="button"
              class="text-white mb-3 block hover:text-black md:text-xs bg-darkblue hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
            >
              Add Invoice Item
            </button>

            <p class="md:text-xs text-center">
              Total Stock Quantity:{{ selectedStock.quantity }}
            </p>
            <p class="md:text-xs text-center">
              Total Stock Amount:₦{{ selectedStock.itemTotal.toLocaleString() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import {
    required,
    numeric,
    alpha_spaces,
    email,
  } from 'vee-validate/dist/rules';
  import { mapMutations } from 'vuex';

  extend('numeric', {
    ...numeric,
    message: '{_field_} must be numbers only',
  });

  extend('email', {
    ...email,
    message: '{_field_} must be a valid email',
  });

  extend('required', required);
  extend('alpha_spaces', {
    ...alpha_spaces,
    message: '{_field_} must be alphabets only',
  });

  extend('min_value', {
    validate(value, { min }) {
      return value >= min;
    },
    params: ['min'],
    message: '{_field_} must be greater or equal to {min}',
  });

  export default {
    name: 'ManageInvoice',
    props: {},
    components: { ValidationProvider, ValidationObserver, FontAwesomeIcon },
    apollo: {
      getCustomers: {
        query: gql`
          query {
            getCustomers {
              _id
              customerName
              phoneNumber
              email
              address
            }
          }
        `,
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
      },
      getStock: {
        query: gql`
          query {
            getStock {
              _id
              itemName
              unit
              unitSellingPrice
              quantityRemaining
            }
          }
        `,
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
      },
      getStockPurchasedItems: {
        query: gql`
          query getStockPurchasedItems($stockId: ID!) {
            getStockPurchasedItems(stockId: $stockId) {
              _id
              stockId {
                _id
              }
              purchaseId {
                serialId
              }
              quantityRemaining
              unitPrice
            }
          }
        `,
        variables() {
          return {
            stockId: this.selectedStock.id,
          };
        },
        error(error) {
          this.$emit('show-alert', {
            type: 'error',
            message: error.message,
          });
        },
        skip() {
          return this.skipGetPurchasedItemsQuery;
        },
      },
    },
    data() {
      return {
        getCustomers: [],
        invoiceItemModal: false,
        invoiceItemModalMode: 'new',
        mode: 'newInvoice',

        loadingSpinner: false,
        getStockPurchasedItems: [],
        getStock: [],
        physicalInvoiceNo: '',
        oldCustomer: {
          id: '',
          name: '',
          index: null,
          phoneNumber: '',
          email: '',
          address: '',
        },
        selectedStock: {
          id: '',
          index: null,
          itemName: '',
          quantity: 0,
          unit: '',
          quantityRemaining: 0,
          itemTotal: 0,
          unitSellingPrice: 0,
          quantityBreakdown: [],
        },
        selectedPurchasedItem: {
          id: '',
          index: null,
          quantityRemoved: 0,
          quantityRemaining: 0,
          unitPrice: '',
          serialId: '',
        },
        icons: {
          pen: require('@/assets/imgs/icons/white-pen.svg'),
        },
        existingCustomerId: null,
        select_component_classes:
          'block w-full pl-3 pr-10 py-2 text-darkblue placeholder-gray-400 transition duration-100 ease-in-out bg-white border-2 border-darkblue rounded-lg  shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',

        customerMode: 'new',
        rich_select_default_component_classes: {
          wrapper: '',
          buttonWrapper: '',
          selectButton:
            'px-3 rounded-lg  md:text-xs  py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
          selectButtonLabel: '',
          selectButtonPlaceholder: 'text-darkblue',
          selectButtonIcon: 'text-gray-600',
          selectButtonClearButton:
            'hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out',
          selectButtonClearIcon: '',
          dropdown:
            '-mt-1 bg-white  md:text-xs  border-b border-gray-300 border-l border-r rounded-b shadow-sm',
          dropdownFeedback: 'pb-2  md:text-xs  px-3 text-gray-400 text-sm',
          loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
          optionsList: '',
          searchWrapper: 'p-2 placeholder-gray-400',
          searchBox:
            'px-3 py-2 bg-gray-50 text-sm  md:text-xs  rounded border focus:outline-none focus:shadow-outline border-gray-300',
          optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
          option: '',
          disabledOption: '',
          highlightedOption: 'bg-blue-100',
          selectedOption:
            'font-semibold bg-gray-100 bg-blue-500 font-semibold text-white',
          selectedHighlightedOption:
            'font-semibold bg-gray-100 bg-darkblue font-semibold text-white',
          optionContent: 'flex justify-between items-center px-3 py-2',
          optionLabel: '',
          selectedIcon: '',
          enterClass: '',
          enterActiveClass: 'opacity-0 transition ease-out duration-100',
          enterToClass: 'opacity-100',
          leaveClass: 'transition ease-in opacity-100',
          leaveActiveClass: '',
          leaveToClass: 'opacity-0 duration-75',
        },
        tableClasses: {
          table:
            'min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border',
          thead: 'border-b-2 border-darkblue ',
          theadTr: ' md:text-xs ',
          theadTh:
            'px-3 py-2 font-bold md:text-center text-left bg-transparent ',
          tbody: 'bg-white divide-y divide-gray-100',
          tr: 'rounded-lg  md:text-xs  text-xs ',
          td: 'px-3 py-2 whitespace-no-wrap',
          tfoot: '',
          tfootTr: '',
          tfootTd: '',
        },

        rich_select_component_classes: {
          wrapper: 'relative',
          buttonWrapper: 'inline-block relative w-full',
          selectButton: 'w-full flex text-left justify-between items-center',
          selectButtonLabel: 'block truncate',
          selectButtonPlaceholder: 'block truncate',
          selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
          selectButtonClearButton:
            'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
          selectButtonClearIcon: 'fill-current h-3 w-3',
          dropdown: 'absolute w-full z-10',
          dropdownFeedback: '',
          loadingMoreResults: '',
          optionsList: 'overflow-auto',
          searchWrapper: 'inline-block w-full',
          searchBox: 'inline-block w-full',
          optgroup: '',
          option: 'cursor-pointer',
          disabledOption: 'opacity-50 cursor-not-allowed',
          highlightedOption: 'cursor-pointer',
          selectedOption: 'cursor-pointer',
          selectedHighlightedOption: 'cursor-pointer',
          optionContent: '',
          optionLabel: 'truncate block',
          selectedIcon: 'fill-current h-4 w-4',
          enterClass: '',
          enterActiveClass: '',
          enterToClass: '',
          leaveClass: '',
          leaveActiveClass: '',
          leaveToClass: '',
        },

        tableHeader: [
          {
            id: 'itemName',
            value: 'itemName',
            text: 'Item Name',
            className: '',
          },
          {
            value: 'quantity',
            text: 'Quantity',
            className: 'fortunate-table',
          },
          {
            value: 'unit',
            text: 'Unit',
            className: 'fortunate-table',
          },
          {
            value: 'unitPrice',
            text: 'Rate(₦)',
            className: 'fortunate-table',
          },
          {
            value: 'itemTotal',
            text: 'Total(₦)',
            className: 'fortunate-table',
          },
          {
            value: 'action',
            text: 'Action',
            className: 'fortunate-table',
          },
        ],
        tableData: [],
      };
    },
    computed: {
      skipGetPurchasedItemsQuery: function () {
        return !this.selectedStock.id ? true : false;
      },
      invoiceTotal: function () {
        let invoiceTotal = 0;
        this.tableData.forEach((invoiceItem) => {
          invoiceTotal += Number(Number(invoiceItem.itemTotal).toFixed(0));
        });
        return invoiceTotal;
      },
      customers: function () {
        return this.getCustomers.map((customer, index) => {
          return {
            value: index,
            text: customer.customerName,
          };
        });
      },
      selectedPurchasedItemId: function () {
        return this.selectedPurchasedItem.id;
      },
      selectedOldCustomersIndex: function () {
        return this.oldCustomer.index;
      },

      selectedStockItemIndex: function () {
        return this.selectedStock.index;
      },
      selectedPurchasedItemIndex: function () {
        return this.selectedPurchasedItem.index;
      },
      stockOptions: function () {
        return this.getStock.map((stock, index) => {
          return {
            value: index,
            text: stock.itemName,
          };
        });
      },
      purchasedItemsOptions: function () {
        return this.getStockPurchasedItems.map((purchasedItem, index) => {
          return {
            value: index,
            text: `Batch:${purchasedItem.purchaseId.serialId} | R:${
              purchasedItem.quantityRemaining
            } | C:₦${purchasedItem.unitPrice.toLocaleString()}`,
          };
        });
      },
    },
    watch: {
      mode: function (newValue) {
        if (newValue == 'newInvoice') {
          window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
          );
          return;
        }
        window.scrollTo(0, 0);
      },
      invoiceItemModal: function (newValue) {
        if (newValue == false) {
          this.selectedStock = {
            id: '',
            index: null,
            itemName: '',
            quantity: 0,
            unit: '',
            quantityRemaining: 0,
            itemTotal: 0,
            unitSellingPrice: 0,
            quantityBreakdown: [],
          };
          this.invoiceItemModalMode = 'new';
          this.invoiceItemEditIndex = null;
        }
      },
      selectedOldCustomersIndex(newValue) {
        this.oldCustomer.id = this.getCustomers[Number(newValue)]._id;
        this.oldCustomer.name =
          this.getCustomers[Number(newValue)].customerName;
        this.oldCustomer.phoneNumber =
          this.getCustomers[Number(newValue)].phoneNumber;
        this.oldCustomer.email = this.getCustomers[Number(newValue)].email;
        this.oldCustomer.address = this.getCustomers[Number(newValue)].address;
      },
      selectedStockItemIndex(newValue) {
        this.selectedStock.unitSellingPrice =
          this.getStock[Number(newValue)].unitSellingPrice;
        this.selectedStock.id = this.getStock[Number(newValue)]._id;
        this.selectedStock.unit = this.getStock[Number(newValue)].unit;
        this.selectedStock.itemName = this.getStock[Number(newValue)].itemName;
        this.selectedPurchasedItem.id = '';
        this.selectedPurchasedItem.index = null;
        this.selectedStock.quantityBreakdown = [];
        this.selectedStock.quantity = 0;
        this.selectedStock.itemTotal = 0;
      },
      selectedPurchasedItemIndex(newValue) {
        if (typeof parseInt(newValue) != 'number') {
          return;
        }
        const selectedItem = this.getStockPurchasedItems[Number(newValue)];
        this.selectedPurchasedItem.id = selectedItem._id;
        this.selectedPurchasedItem.unitPrice = selectedItem.unitPrice;
        this.selectedPurchasedItem.quantityRemaining =
          selectedItem.quantityRemaining;
        this.selectedPurchasedItem.serialId = selectedItem.purchaseId.serialId;
      },
    },
    methods: {
      ...mapMutations(['setBreadcrumbs']),

      ShowInvoiceItemModal(mode = 'new') {
        this.invoiceItemModalMode = mode;
        this.invoiceItemModal = true;
      },
      DeleteInvoiceItem(index) {
        this.tableData.splice(index, 1);
      },
      AddInvoiceItem() {
        const duplicateInvoiceItemCheck = this.tableData.find((invoiceItem) => {
          return invoiceItem.stockId === this.selectedStock.id;
        });

        if (duplicateInvoiceItemCheck != undefined) {
          this.$emit('show-alert', {
            type: 'error',
            message: `${this.selectedStock.itemName} has already been used in this invoice`,
          });

          return;
        }

        this.tableData.push({
          itemName: this.selectedStock.itemName,
          quantity: this.selectedStock.quantity,
          unit: this.selectedStock.unit,
          unitPrice: this.selectedStock.unitSellingPrice,
          stockId: this.selectedStock.id,
          itemTotal: this.selectedStock.itemTotal,
          quantityBreakdown: this.selectedStock.quantityBreakdown,
          action: 'action',
        });
        this.$emit('show-alert', {
          type: 'notification',
          timeout: 3500,
          message: `${this.selectedStock.itemName} has been added to invoice items`,
        });
        this.selectedStock = {
          id: '',
          index: null,
          itemName: '',
          quantity: 0,
          unit: '',
          quantityRemaining: 0,
          itemTotal: 0,
          unitSellingPrice: 0,
          quantityBreakdown: [],
        };
        this.mode = 'newInvoice';
      },
      SubmitCustomer() {
        if (this.tableData.length < 1) {
          this.$emit('show-alert', {
            type: 'error',
            message: `You need to add items to the invoice before you can save it`,
          });

          return;
        }
        this.SaveInvoice();
      },
      async SaveInvoice() {
        this.loadingSpinner = true;

        const invoiceItems = this.tableData.map((invoiceItem) => {
          invoiceItem.quantityBreakdown = invoiceItem.quantityBreakdown.map(
            (quantityBreakdown) => {
              return {
                quantityRemoved: quantityBreakdown.quantityRemoved,
                purchasedItemId: quantityBreakdown.purchasedItemId,
              };
            }
          );
          return {
            quantity: invoiceItem.quantity,
            unitPrice: Number(invoiceItem.unitPrice),
            stockId: invoiceItem.stockId,
            quantityBreakdown: invoiceItem.quantityBreakdown,
          };
        });
        const invoiceData = {
          physicalInvoiceNo: this.physicalInvoiceNo,
          customerId: this.oldCustomer.id,
          invoiceItems,
        };
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation ($invoice: InvoiceInput!) {
                createInvoice(invoice: $invoice) {
                  _id
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              invoice: invoiceData,
            },
          });

          this.loadingSpinner = false;
          if (!errors && !!data.createInvoice._id) {
            this.$emit('show-alert', {
              type: 'success',
              message: `Invoice for ${this.oldCustomer.name} created successfully`,
            });

            return this.$router.push('/app/invoices');
          }
          if (!!errors && data == null) {
            return this.$emit('show-alert', {
              type: 'error',
              message: errors[0].message,
            });
          }
          this.$emit('show-alert', {
            type: 'error',
            message: `Error creating invoice,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit('show-alert', {
            type: 'error',
            message: `Error creating invoice,check your network or contact your developer`,
          });
        }
      },
      RemoveQtyBreakdown(index) {
        this.selectedStock.quantity -= Number(
          this.selectedStock.quantityBreakdown[index].quantityRemoved
        );
        const itemTotal =
          Number(this.selectedStock.quantityBreakdown[index].quantityRemoved) *
          Number(this.selectedStock.unitSellingPrice);

        this.selectedStock.itemTotal -= Number(Number(itemTotal.toFixed(0)));
        this.selectedStock.quantityBreakdown.splice(index, 1);
      },
      AddStockBreakdown() {
        const purchasedItemIdCheck = this.selectedStock.quantityBreakdown.find(
          (quantityBreakdown) => {
            return (
              quantityBreakdown.purchasedItemId ===
              this.selectedPurchasedItem.id
            );
          }
        );

        if (purchasedItemIdCheck != undefined) {
          this.$emit('show-alert', {
            type: 'error',
            message: `Batch${this.selectedPurchasedItem.serialId} has already been used for this stock item`,
          });

          return;
        }
        if (
          Number(this.selectedPurchasedItem.quantityRemoved) >
          Number(this.selectedPurchasedItem.quantityRemaining)
        ) {
          this.$emit('show-alert', {
            type: 'error',
            message: `Insufficient stock in Batch${this.selectedPurchasedItem.serialId} to complete this request \n,reduce your quantity or select another batch`,
          });

          return;
        }
        const itemTotal =
          Number(this.selectedPurchasedItem.quantityRemoved) *
          Number(this.selectedStock.unitSellingPrice);
        this.selectedStock.itemTotal += Number(Number(itemTotal).toFixed(0));
        this.selectedStock.quantity += Number(
          this.selectedPurchasedItem.quantityRemoved
        );
        this.selectedStock.quantityBreakdown.push({
          serialId: this.selectedPurchasedItem.serialId,
          purchasedItemId: this.selectedPurchasedItem.id,
          quantityRemoved: Number(this.selectedPurchasedItem.quantityRemoved),
        });
      },
      GetPurchasedItems() {},

      ChangeCustomerMode(mode) {
        this.customerMode = mode;
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: 'Invoices', pathName: 'invoices' },
        {
          displayName: 'Manage Invoice',
          pathName: 'manage_invoice',
        },
      ]);

      this.$apollo.queries.getCustomers.setOptions({
        fetchPolicy: 'network-only',
      });
      this.$apollo.queries.getStock.setOptions({
        fetchPolicy: 'network-only',
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
